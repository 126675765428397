import { notNull, parseDateV2, hasOnlyValidEnglishNameCharacters, formatUSPhoneNumber, Enum_Question_Type, Enum_Survey_Access_Restriction } from '@cyren/common-lib';
import { differenceInYears } from 'date-fns';
import { difference, first, isEmpty, pullAll, size, toNumber, trim, union, uniq, values } from 'lodash';
import { useRecoilValue } from 'recoil';
import { conditionHelpers, matchAgeRange } from '../../report/helper-functions';
import { qsIncludesGlobal } from '../../report/match-utils';
import { useData } from '../../report/use-data';
import { getTextAnswer, isQsAnswered } from '../../utils/models/question-answer';
import {
  ConditionType,
  Key,
  Keys,
  QsErrorMap,
  QuestionAnswerMap,
  QuestionTreeType,
  QuestionType,
  TreeState,
  isIncludeCondition,
} from '../patient-types';
import { filterEligibleConditions } from './condition';
import { PaReportState, useReportState } from './use-report';
import { useQuestionHelpers } from '../../report/use-question-helpers';
import { noneAboveKey, otherKey } from '../../report/report-config';
import { TQuestion } from '../../graphql-enhanced';

export function useTreeHelpers({ treeState, treeOptions }: { treeState?: TreeState | null, treeOptions?: { suppressSxCollectors?: boolean } }) {
  const [
    {
      dataState: { survey, visit },
    },
    { getTreeByKey, getQuestionsByKeys, getQuestionByKey },
  ] = useData();

  const [{ selectedSxTrees },] =
    useReportState({
    });
  const [refineQuestion] = useQuestionHelpers();

  const reportState = useRecoilValue(PaReportState);
  const questionKeys = getTreeByKey(treeState?.treeKey || '')?.entryQuestionKeys || [];
  const questions = getQuestionsByKeys(questionKeys);

  const visibleQs = questions.filter((q) => {
    const visible = isQuestionVisible({ question: q, treeKey: treeState?.treeKey });
    return visible;
  });

  const visibleQsKeys = visibleQs.map((q) => q.questionKey);
  const hiddenQsKeys = pullAll([...questionKeys], visibleQsKeys);

  let requiredVisibleQs = visibleQs.filter((q) => q.required);
  if (survey?.access_restriction && survey?.access_restriction === Enum_Survey_Access_Restriction.ConferenceDemo) {
    // in conference demo mode, only required system key questions are actually required
    requiredVisibleQs = visibleQs.filter((q) => {
      return q.required && !isEmpty(q.systemKey)
    }) as TQuestion[];
  }
  const requiredVisibleQsKeys = requiredVisibleQs.map((q) => q.questionKey);

  // some fields, like a phone number, may not be required, but they must be validated in order to be accepted
  const validationRequiredQsKeys = visibleQs.filter((q) => ['phone_number'].includes(q.type || "")).map((q) => q.questionKey);

  function getHiddenQsKeys() {
    return hiddenQsKeys;
  }

  function isQuestionAnswered({
    questionKey,
    treeAsMap,
  }: {
    questionKey: Key;
    treeAsMap: QuestionAnswerMap;
  }) {
    const question = questions?.find((q) => q.questionKey === questionKey);

    const isQAnswered = isQsAnswered({ question, treeAsMap });

    // handle special cases
    if (question?.type === Enum_Question_Type.SxCollect && !isEmpty(selectedSxTrees)) {
      return true;
    }

    return isQAnswered;
  }

  function isQsAllAnswered({ qsKeys }: { qsKeys: Keys }) {
    const treeAsMap = treeState?.answerMap;

    if (!treeAsMap) {
      return false;
    }

    const foundQsNotAnswered = qsKeys.some((questionKey) => {
      const qNotAnswered = !isQuestionAnswered({ questionKey, treeAsMap });

      if (qNotAnswered) {
        // Note this is for debugging
        // eslint-disable-next-line
        // console.log('questionKey', questionKey, treeAsMap);
      }

      return qNotAnswered;
    });

    if (foundQsNotAnswered) {
      return false;
    }

    return true;
  }

  function getQsErrorMap({ qsKeys }: { qsKeys: Keys }) {
    const treeAsMap = treeState?.answerMap;

    if (!treeAsMap) {
      return {};
    }

    const qsErrorMap = {} as QsErrorMap;

    qsKeys.forEach((questionKey) => {
      const qNotAnswered = !isQuestionAnswered({ questionKey, treeAsMap });
      if (qNotAnswered && requiredVisibleQsKeys.includes(questionKey)) {
        qsErrorMap[questionKey] = ['required'];
        return;
      }

      const question = questions?.find((q) => q.questionKey === questionKey);

      /*
      // Kathy has requested temporarily disabling this feature while she is in Korea so that she can
      // explore options to use the application there.  This is only being applied to a special 1.19.1
      // branch and will not be kept for 1.20.0
      if (['firstName', 'lastName'].includes(question?.systemKey || '')) {
        const restrictedCharacterText = trim(getTextAnswer(treeAsMap[questionKey]));
        if (!hasOnlyValidEnglishNameCharacters(restrictedCharacterText)) {
          qsErrorMap[questionKey] = ['inline error'];
        }
      }
      */

      if (question?.type === 'date_dob') {
        const dateStr = trim(getTextAnswer(treeAsMap[questionKey]));
        const ageRestriction = question?.typeOptions?.ageRestriction;

        const validDate = parseDateV2({
          dateStr,
          formatStr: 'MM/dd/yyyy',
        });

        if (ageRestriction && validDate) {
          const age = differenceInYears(new Date(), validDate);

          if (ageRestriction && age != null && (age === 0 || age < ageRestriction)) {
            qsErrorMap[questionKey] = ['age restriction'];
            // return;
          }
        }
      }

      if (['phone'].includes(question?.systemKey || '')) {
        const restrictedCharacterText = trim(getTextAnswer(treeAsMap[questionKey]));
        const formattedNumber = formatUSPhoneNumber(restrictedCharacterText);
        if (size(restrictedCharacterText)) {
          if (!formattedNumber) {
            qsErrorMap[questionKey] = ['invalid phone'];
          }
          else if ((!question?.typeOptions?.verifyPhoneNumber || question?.typeOptions?.verifyPhoneNumber === "required") &&
            formattedNumber !== formatUSPhoneNumber(visit?.verifiedPhone || "")) {

            qsErrorMap[questionKey] = ['unverified phone'];
          }
        }
      }
    });

    return qsErrorMap;
  }

  function getQsKeysNotAnswered({ qsKeys }: { qsKeys: Keys }) {
    const treeAsMap = treeState?.answerMap;

    if (!treeAsMap) {
      return [];
    }

    const keysNotAnswered = qsKeys
      .map((questionKey) => {
        const qNotAnswered = !isQuestionAnswered({ questionKey, treeAsMap });

        return qNotAnswered ? questionKey : null;
      })
      .filter(notNull);

    return keysNotAnswered;
  }

  const isAllRequiredQuestionsAnswered = isQsAllAnswered({
    qsKeys: requiredVisibleQsKeys,
  });

  const qsKeysNotAnswered = getQsKeysNotAnswered({
    qsKeys: requiredVisibleQsKeys,
  });

  const qsErrorMap = getQsErrorMap({
    qsKeys: union(requiredVisibleQsKeys, validationRequiredQsKeys),
  });

  const noErrorFoundInQss = !values(qsErrorMap).some((v) => size(v) > 0);

  function evaluateCondition({ condition, question }:
    { condition: ConditionType; question?: QuestionType; treeKey?: Key }) {

    if (!treeState) return true;

    const { operator } = condition;

    // matching with operator
    if (operator != null) {
      const treeAsMap = treeState?.answerMap;
      const firstValue = first(treeAsMap[condition.qsKey || '']?.answerValues);

      if (firstValue != null) {
        if (operator === 'lte' && toNumber(firstValue) <= toNumber(condition.value)) {
          return true;
        }
        if (operator === 'lt' && toNumber(firstValue) < toNumber(condition.value)) {
          return true;
        }
        if (operator === 'gte' && toNumber(firstValue) >= toNumber(condition.value)) {
          return true;
        }
        if (operator === 'gt' && toNumber(firstValue) > toNumber(condition.value)) {
          return true;
        }
        if (operator === 'eq' && toNumber(firstValue) === toNumber(condition.value)) {
          return true;
        }
        if (operator === 'ne' && toNumber(firstValue) !== toNumber(condition.value)) {
          return true;
        }
      }
    }

    if (condition.func != null) {
      const func = conditionHelpers[condition.func];

      const result = func({ question, treeState, reportState, survey });
      return result;
    }

    if (condition.category === 'age-range') {
      const { ageMin, ageMax, ageUnit } = condition;
      return matchAgeRange({ reportState, ageMin, ageMax, ageUnit });
    }


    if ('type' in condition && isIncludeCondition(condition)) {
      return qsIncludesGlobal({ condition, question, treeState, treeStates: reportState.treeStates });
    }

    return false;
  }

  function isQuestionVisible({ question, treeKey }:
    {
      question: QuestionType;
      treeKey?: Key,
    }) {
    if (!treeState) return true;

    const { enabled, showConditions } = question;

    if (enabled === false) return false;

    if (treeOptions?.suppressSxCollectors && question.type === Enum_Question_Type.SxSelect) {
      return false;
    }

    if (!isEmpty(showConditions)) {
      const eligibleConditions = filterEligibleConditions({
        treeKey,
        showConditions,
      });

      // if no eligible conditions, show the question
      if (isEmpty(eligibleConditions)) {
        return true;
      }

      const showConditionMet = eligibleConditions?.some((condition) => {
        return evaluateCondition({ condition, question, treeKey });
      });

      if (!showConditionMet) {
        return false;
      }
    }

    return true;
  }

  function isTreeVisible({ treeKey }: { treeKey: Key }) {
    if (!treeState) return true;

    const tree = getTreeByKey(treeKey);
    if (!tree) return true;

    const { enabled, showConditions } = tree;

    if (enabled === false) return false;

    if (!isEmpty(showConditions)) {
      const showConditionMet = showConditions.some((condition: ConditionType) => {
        return evaluateCondition({ condition, treeKey });
      });

      if (!showConditionMet) {
        return false;
      }
    }

    return true;
  }

  function getAllAssociatedSymptomKeys(rootSymptoms: QuestionTreeType[], suppessSymptoms: string[]) {

    const associatedSymptomQuestions = uniq(rootSymptoms.reduce((prev, sxTree) => {

      const symptomKeys: string[] = sxTree.entryQuestionKeys.map(key => getQuestionByKey(key))
        .filter(q => q?.type === Enum_Question_Type.SxSelect)
        .map(q => q?.questionKey)
        .filter(i => i !== undefined) as string[];

      return [...prev,
      ...symptomKeys
      ];
    }, [] as string[]));

    const associatedSymptomKeys: string[] = uniq(associatedSymptomQuestions.reduce((prev, qsKey) => {

      // filter out options that are not visible to the user
      const questionRaw = getQuestionByKey(qsKey);
      if (!questionRaw) {
        return prev;
      }
      const [{ question: questionRefined }] = refineQuestion({ question: questionRaw });
      return [...prev,
      ...questionRefined?.answerKeys || []
      ]
      return prev;
    }, [] as string[])).filter(sxKey => sxKey !== noneAboveKey && sxKey !== otherKey);

    const finalSymptomKeys = difference(associatedSymptomKeys, suppessSymptoms || []);

    return finalSymptomKeys;
  }

  function getAssociatedSymptomKeys(sourceRestrictions: 'includeIntelligent' | 'excludeIntelligent') {
    const selectedIntelligentSymptoms = selectedSxTrees.filter(sx => reportState?.intelligentSymptoms?.symptomKeys.find(key => key === sx.treeKey));
    let associatedIntelligentSymptoms = getAllAssociatedSymptomKeys(selectedIntelligentSymptoms, reportState?.intelligentSymptoms?.symptomKeys || []);

    // handle possibility that when the user selects an associated symptom off the list, it may trigger additional associated symptoms.
    const selectedAssociatedIntelligentSymptoms = selectedSxTrees.filter(sx => associatedIntelligentSymptoms.find(key => key === sx.treeKey));
    if (!isEmpty(selectedAssociatedIntelligentSymptoms)) {
      // in theory, we could recurse multiple levels, but this should handle the main cases we are concerned about
      associatedIntelligentSymptoms = getAllAssociatedSymptomKeys
        (union(selectedIntelligentSymptoms, selectedAssociatedIntelligentSymptoms),
          reportState?.intelligentSymptoms?.symptomKeys || []);
    }

    if (sourceRestrictions === 'includeIntelligent') {
      return associatedIntelligentSymptoms;
    }
    if (sourceRestrictions === 'excludeIntelligent') {
      return getAllAssociatedSymptomKeys(selectedSxTrees,
        union(associatedIntelligentSymptoms,
          reportState?.intelligentSymptoms?.symptomKeys)
      );
    }
    return [];

  }

  return [
    { qsErrorMap, isValid: noErrorFoundInQss && isAllRequiredQuestionsAnswered, qsKeysNotAnswered },
    {
      isQuestionVisible,
      isTreeVisible,
      getHiddenQsKeys,
      getAssociatedSymptomKeys
    },
  ] as const;
}
